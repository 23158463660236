.SectionContent {
    padding: 100px;
}

@media only screen and (max-width: 700px) {
    .SectionContent {
        padding: 50px;
    }
}

@media only screen and (max-width: 420px) {
    .SectionContent {
        padding: 20px;
    }
}

@media only screen and (max-width: 900px) {
    .HeroContent {
        font-size: 70pt !important;
    }
}

@media only screen and (max-width: 600px) {
    .HeroContent {
        font-size: 60pt !important;
    }
}

/*
    Very gross hack to enable -webkit-fill-available in Safari,
    but not desktop Chrome, which seems to implement it
    inconsistently from Safari and breaks layout.
*/
@media not all and (min-resolution: .001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
        .HeroSection {
            height: -webkit-fill-available !important;
        }
    }
}

.HeroSection {
    height: 100vh;
}

.HeroContent {
    font-size: 90pt;
    text-shadow: 2px 2px 30px rgba(0, 0, 0, 0.8);
}

.Interstitial {
    font-size: 45pt;
}
