.Hamburger {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}

.Hamburger--inverted {
    filter: invert(100%);
}

.hamburger-box {
    transition: 0.2s;
}

#hamburger {
    backdrop-filter: blur(10px);
    border-radius: 10px;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-content: center;
    opacity: 0;
}

#hamburger.is-active {
    backdrop-filter: none;
}

@media only screen and (max-width: 578px) {
    #hamburger {
        opacity: 1;
    }
}
