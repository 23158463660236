.QuestionContainer {
    display: flex;
    margin-left: -30px;
}

.QuestionQ {
    flex: 0 0 50px;
    font-weight: bold;
    font-size: 14pt;
    margin-left: 10px;
}

.Question {
    text-align: left;
    font-weight: bold;
    padding-bottom: 10px;
    font-size: 14pt;
}

.Answer {
    text-align: left;
    font-size: 14pt;
    margin-left: 30px;
    margin-bottom: 40px;
}

.Answer p {
    padding-bottom: 10px;
}
