.EventList {
    width: 800px;
}

.EventContainer {
    backdrop-filter: blur(20px);
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 20px;
    color: black;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;

    transition: box-shadow 0.4s,
                transform 0.4s;
}

.EventDetails {
    transition: max-height 0.5s,
                opacity 0.5s;

    padding-top: 20px;
    font-size: 12pt;
    text-align: left;
}

.EventDetails-shown {
    max-height: 600px;
}

.EventDetails-hidden {
    max-height: 0;
    opacity: 0;
}

.EventContainer:hover {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4);
    transform: translateY(-5px);
    cursor: pointer;
}

.EventBlurbHeader {
    margin-bottom: 15px;
}

.EventSmallText {
    font-size: 12pt;
}

.EventSubsections hr {
    display: none;
}

.EventSectionBG {
    background: url("../img/kirigin-tall.jpg") no-repeat;
}

@media only screen and (max-width: 1000px) {
    .EventList {
        width: auto;
    }

    .EventSection {
        padding-left: 0;
        padding-right: 0;
    }
}

@media only screen and (max-width: 600px) {
    .EventSectionBG {
        background: url("../img/kirigin-tall-mobile.jpg") no-repeat;
    }
}

@media only screen and (max-width: 575px) {
    .EventSubsections hr {
        max-width: 200px;
        margin: 20px 0 20px 0;
        display: inherit;
    }
}
