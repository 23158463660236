.OverlayMenu {
    position: fixed;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 999;
    padding: 20px;
    transition: 0.3s;
    pointer-events: none;
    background-color: transparent;
}

.OverlayMenu.shown {
    backdrop-filter: blur(20px);
    background-color: rgba(143, 103, 0, 0.5);
    pointer-events: all;
}

.OverlayMenuContent {
    opacity: 0;
    pointer-events: none;
    transition: 0.2s;
}

.OverlayMenuContent.shown {
    opacity: 1;
    pointer-events: all;
}
