.RSVPOption {
    border: 1px solid rgb(200, 200, 200);
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;

    transition: 0.2s;
    transition-property: color, border, background-color;
}

.RSVPOption:hover {
    border: 1px solid black;
}

.RSVPOption.No.Selected {
    border: 1px solid red;
    color: red;
    background-color: rgb(255, 240, 240);
}

.RSVPOption.Yes.Selected {
    border: 1px solid green;
    color: green;
    background-color: rgb(233, 255, 227);
}

.RSVPOptionTitle {
    font-size: 30pt;
    padding-bottom: 10px;
}

.RSVPYesNo {
    text-align: center;
}

.RSVPFormRow {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
}

.RSVPRIP {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
}

.RSVPSubmitSectionContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}


.RSVPSubmitSection {
    width: 500px;
    display: flex;
    flex-direction: column;
}

.RSVPSubmit {
    font-size: 18pt;
    padding-top: 10px;
    padding-bottom: 10px;
}

.Separator {
    margin-top: 60px;
    margin-bottom: 60px;
}

@media only screen and (max-width: 578px) {
    .Separator {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .RSVPOption {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}
