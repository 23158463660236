a.NavLink {
    white-space: nowrap;
    display: inline-block;
    position: relative;
}

a.NavLink:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    left: 0;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

a.NavLink:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

/* Hero links */

a.NavLink.Hero {
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 1);
    color: white;
    text-decoration: none;
}

a.NavLink.Hero:hover {
    color: white;
}

a.NavLink.Hero:after {
    background-color: white;
    height: 2px;
    bottom: -5px;
    filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.4));
}

/* Nav bar links */

a.NavLink.NavBarLink {
    color: gray;
    text-decoration: none;
    transition: color 0.25s;
}

a.NavLink.NavBarLink:hover {
    color: black;
}

a.NavLink.NavBarLink:after {
    background-color: black;
    height: 0.5px;
    bottom: -11.5px;
}

.NavBar-over-hero a.NavLink.NavBarLink {
    color: white;
}

.NavBar-over-hero a.NavLink.NavBarLink:hover {
    color: white;
}

.NavBar-over-hero a.NavLink.NavBarLink:after {
    background-color: white;
}

/* Hamburger links */

a.NavLink.HamburgerLink {
    color: white;
    text-decoration: none;
}

a.NavLink.HamburgerLink:hover {
    color: white;
}

a.NavLink.HamburgerLink:after {
    background-color: white;
    height: 2px;
    bottom: -5px;
}
