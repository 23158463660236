.NavBar-over-hero {
    background-color: clear;
}

.NavBar-over-rest {
    background-color: white;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.2)
}

.NavBar-blur {
    backdrop-filter: blur(20px);
}

@media only screen and (max-width: 578px) {
    .navbar.NavBar-autohide {
        display: none !important;
    }
}

.NavBar-animation {
    transition: 0.2s;
}

.App {
    text-align: center;
    font-family: essonnes-text, serif;
    font-weight: 400;
    font-style: normal;
    font-size: 17pt;
    width:  100%;
}

.body {
    line-height: 1.5;
}

.footerSeparator {
    padding-left: 20px;
    padding-right: 20px;
}

.Blurb {
    padding-right: 6%;
    padding-left: 6%;
}

@media only screen and (max-width: 1000px) {
    .Blurb {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

@media only screen and (max-width: 600px) {
    .footerSeparator {
        display: none;
    }

    .footerSeparator-compact {
        display: inherit !important;
    }
}

.footerSeparator-compact {
    display: none;
}

.bounce {
    -moz-animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
}

@keyframes bounce {
    0% {
        transform: translateY(0);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: translateY(50px);
        opacity: 0;
    }
}
