.NotYouButton {
    /*
        Make "Not You?" effectively take up zero width, so it doesn't
        knock the person's name off-center
    */
    width: 100px;
    margin-left: -100px;
    transform: translateX(100px);
}


@media only screen and (max-width: 578px) {
    .NotYouButton {
        width: auto;
        margin-left: 0;
        transform: none;
    }
}
